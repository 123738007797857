/**
 * @file
 * Prevents the right-click context menu from appearing on a webpage.
 */

(function ($) {
  'use strict';

  //$(window).on('load', blockRightClick);

  /**
   * The function `blockRightClick` prevents the right-click context menu from appearing on a webpage.
   */
  function blockRightClick(){
    $(document).on('contextmenu', function (e) {
      e.preventDefault();
    });
  }

})(jQuery);
